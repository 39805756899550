




































































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import {
  ResumeInterviewPayload,
  StartInterviewPayload
} from "@/store/modules/recruiter/interfaces";
import {
  GET_TOP_MATCHING_JOB,
  NEP_INTERVIEW,
  NEP_INTERVIEW_ANSWER,
  NEP_INTERVIEW_ID,
  SUBMIT_CANDIDATE_MEDIA
} from "@/store/modules/candidates/constants";
import { UPDATE_INTERVIEW_STATUS } from "@/store/modules/recruiter/constants";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import { generate_random_key, wait_until } from "@/utils/global";
import {
  GET_JOB_BY_ID,
  UPLOAD_FILE_CHUNK
} from "@/store/modules/common/constants";
import {
  Interview,
  SpeechRecognitionResult
} from "@/interfaces/responses/interviews/interviews";
import {
  InterviewChat,
  InterviewRecords,
  InterviewRoles
} from "@/interfaces/candidate/candidate_interview";
import moment from "moment";
import InterviewLoading from "@/components/candidate/interviews/InterviewLoading.vue";
import fixWebmDuration from "fix-webm-duration";
import ProgressUploader from "@/components/shared/ProgressUploader.vue";

export default Vue.extend({
  name: "NepInterview",
  components: { InterviewLoading, GoBackHeader, ProgressUploader },

  data() {
    return {
      // Thank you message mp3 after interview completion
      interview_complete_msg:
        "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/interview_end_msg.mp3",
      data_loading: false, // To check if data is loading or not
      // Thank you message after interview completion
      interview_end_text:
        "Thank you for participating in the interview process. " +
        "We appreciate your time and interest in our company. " +
        "Our team will review your application and interview performance thoroughly. " +
        "If there are any further updates or next steps, we will reach out to you accordingly. " +
        "Thank you again for your time, and we wish you the best of luck in your job search.",
      interview_title: "Interviewing for ", // Interview title
      bot_ans_loading: false, // To check bot ans is fetching from the server or not
      interview_history: [] as InterviewChat[], // Interview history array
      speech_mode: false, // To check user is speaking or not
      recognition: null as typeof window.webkitSpeechRecognition | null, // Speech recognition object
      transcript: "", // User speech into text
      interview_id: null as null | number, // Interview id
      bot_speaking: true, // To check bot is speaking or not
      bot_image: require("@/assets/images/female-bot.png"), // Bot image
      audio_video_enabled: false, // To check if audio/video is enabled or not
      permission_allowed: false, // To check if permission is allowed or not
      media_recorder: null as MediaRecorder | null, // Media recorder object
      recorded_blobs: [] as Blob[], // Recorded blobs
      interview_completed: false, // To check if interview is completed or not
      media_uploading: false, // To check if media uploading is in progress or not
      media_uploading_title: "", // Media uploading title
      media_uploading_progress: 0, // Media uploading progress,
      camera_recording_start_time: new Date(), // For camera recording start time
      camera_recording_end_time: new Date(), // For camera recording end time
      ttsAudio: null as HTMLAudioElement | null, // Audio object
      interview_finished: false,
      interview_status: Interview.Status.TechnicalInterview,
      audio_text:
        "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/nep/interview/question1.mp4",
      interview_rec: [] as InterviewRecords[],
      int_index: 0,
      candidate_id: 0
    };
  },
  computed: {
    InterviewRoles() {
      return InterviewRoles;
    },
    ...mapGetters("candidate", {
      get_top_matching_job: GET_TOP_MATCHING_JOB
    }),
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS
    })
  },
  async mounted() {
    const details = await this.get_nep_interview_id();
    if (details.interview_status === Interview.Status.Finished) {
      this.set_root_notification(
        "Interview already completed, View Interview Report"
      );
      this.$router.push("/candidate/nep-report");
      return;
    }
    await this.check_permissions(); // Check mic/video permissions
    if (!this.audio_video_enabled) return; // If mic/video permissions are declined => return
    // Fetch interview details

    this.interview_id = details.id;
    this.candidate_id = details.candidate_id;
    const interview_details = await this.fetch_interview_details();
    // If interview details not found => navigate to interview page
    if (interview_details.length <= 0) {
      this.interview_finished = true;
      await this.invalid_interview_error();
      return;
    }
    this.audio_text = interview_details[0].url;
    this.interview_rec = interview_details;
    this.interview_title += "NEP Interview";
    this.push_interview_history_obj(
      interview_details[0].text,
      this.format_interview_date(),
      this.bot_image
    );
    await this.playAudio(interview_details[0].url, false); // Play initial questions
  },
  methods: {
    generate_random_key,
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("common", {
      fetch_job_by_id: GET_JOB_BY_ID,
      upload_file_chunk: UPLOAD_FILE_CHUNK
    }),
    ...mapActions("recruiter", {
      update_interview_status: UPDATE_INTERVIEW_STATUS
    }),
    ...mapActions("candidate", {
      submit_candidate_media: SUBMIT_CANDIDATE_MEDIA,
      fetch_interview_details: NEP_INTERVIEW,
      get_nep_interview_id: NEP_INTERVIEW_ID,
      submit_nep_answer: NEP_INTERVIEW_ANSWER
    }),
    // Function to check mic/video permissions
    // If mic/video permissions are allowed => config speech recognition & video recording
    // If mic/video permissions are declined => set permission_allowed to false
    async check_permissions() {
      this.permission_allowed = false; // Set permission allowed to false
      this.audio_video_enabled = true; // Set audio/video enabled to true
      try {
        // Get user media
        const result = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true
        });
        // If user media is active => config speech recognition & video recording
        if (result.active) {
          this.audio_video_enabled = true;
          this.permission_allowed = true;
          await this.config_speech_recognition(); // Config speech recognition
          await this.config_video_recording(result); // Config video recording
        }
      } catch (e) {
        this.permission_allowed = false;
        this.audio_video_enabled = false;
      }
    },
    // Function to navigate back to the interviews' page
    async invalid_interview_error() {
      // Set interview completed to false so that complete_interview function won't be called after media recorder stop
      this.interview_completed = false;
      this.stop_media_recorder(); // Stop media recorder
      this.set_root_error("Interview Details Not Found"); // Set root error
      await this.$router.push("/candidate/dashboard"); // Navigate to the interviews' page
    },
    // Function to get initial interview questions
    // Push initial interview questions to interview history
    // Return initial interview questions file
    async init_interview() {
      this.bot_ans_loading = true;
      // Get the initial interview questions
      // Api call to get initial interview questions
      // If failed to get initial interview questions => navigate to the interviews' page

      this.bot_ans_loading = false; // Set bot ans loading to false
      // Push initial interview questions to interview history

      // return this.interview_history[this.index].url; // Return initial interview questions file
    },
    // Function to format interview date and return it in string format
    format_interview_date(date: number = moment.now()): string {
      return moment(date).format("ddd, h:mm A").toString();
    },
    // Function to push interview history object to interview history array
    push_interview_history_obj(
      content: string,
      created_at: string,
      picture: string,
      role: InterviewRoles = InterviewRoles.BOT,
      id: number = generate_random_key()
    ) {
      this.interview_history.push({
        content,
        role,
        picture,
        created_at,
        id
      });
    },
    // Function to config speech recognition
    // If speech recognition is not supported => set permission_allowed to false
    // If speech recognition is supported => config speech recognition
    async config_speech_recognition() {
      try {
        const speech_recognition = window.webkitSpeechRecognition; // Get speech recognition object
        this.recognition = new speech_recognition(); // Create a new speech recognition object
        this.recognition.interimResults = true; // Set interim results to true so that we can get the result before the user stops speaking
        this.recognition.continuous = true; // Set continuous to true so that we can get the result continuously
        this.recognition.onresult = this.handle_user_speech; // Set on result function
      } catch (e) {
        this.permission_allowed = false;
        this.audio_video_enabled = false;
      }
    },
    // Function to config video recording
    // If video recording is not supported => set permission_allowed to false
    // If video recording is supported => config video recording
    async config_video_recording(stream: MediaStream) {
      try {
        this.media_recorder = new MediaRecorder(stream); // Create a new media recorder object
        this.media_recorder.start(); // Start media recorder
        this.camera_recording_start_time = new Date(); // Set camera recording start time
        const video = this.$refs.camera as HTMLVideoElement; // Get video element
        video.srcObject = stream; // Set video source
        this.media_recorder.ondataavailable = this.media_recorder_data; // Set on data available function
        this.media_recorder.onstop = this.handle_media_recorder_stop; // Set on stop function
      } catch (e) {
        this.permission_allowed = false;
        this.audio_video_enabled = false;
      }
    },
    // Function to handle media recorder stop
    // If media recorder is active => stop media recorder
    // If interview id exist & interview is completed => complete interview
    async handle_media_recorder_stop() {
      if (this.media_recorder) {
        this.media_recorder.stream.getTracks().forEach((track) => track.stop()); // Stop media recorder
        this.camera_recording_end_time = new Date(); // Set camera recording end time
        this.media_recorder.ondataavailable = null; // Set on data available to null
        this.media_recorder.onstop = null; // Set on stop to null
        this.media_recorder = null; // Set media recorder to null
        // If interview id exist & interview is not completed => complete interview
        if (this.interview_id && this.interview_completed)
          await this.complete_interview(this.interview_id);
      }
    },
    // Function to handle media recorder data
    // If data exist => push data to recorded blobs
    async media_recorder_data(data: BlobEvent) {
      // If data exist => push data to recorded blobs
      if (data.data && data.data.size > 0) {
        this.recorded_blobs.push(data.data); // Push data to recorded blobs
      }
    },
    /**
     * Function to handle user speech
     * @param {string} result => user voice
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handle_user_speech(result: any) {
      this.transcript = Array.from(result.results as SpeechRecognitionResult[])
        .map((resultItem: SpeechRecognitionResult) => {
          return resultItem[0].transcript;
        })
        .join("");
    },
    // Function to send user response
    // If speech mode is active => stop speech recognition
    // Set bot speaking to true
    // Set bot ans loading to true
    // Get user response
    // Push user response to interview history
    // If interview id exist => resume interview
    async send_user_response() {
      // If speech mode is active => stop speech recognition
      if (this.speech_mode) {
        this.speech_mode = false;
        this.recognition.stop();
      }
      this.bot_speaking = true;
      const answer = this.transcript;
      this.push_interview_history_obj(
        answer,
        this.format_interview_date(),
        this.get_user.avatar_uri,
        InterviewRoles.USER
      );
      const result = await this.submit_nep_answer({
        interview_id: this.interview_id,
        candidate_id: this.candidate_id,
        answer: answer,
        question_text: this.interview_rec[this.int_index].text,
        complete: this.int_index === 3
      });
      if (result) {
        this.int_index += 1;
        if (this.int_index === 4) {
          this.interview_completed = true;
          this.stop_media_recorder();
          return;
        }
        this.audio_text = this.interview_rec[this.int_index].url;
        this.push_interview_history_obj(
          this.interview_rec[this.int_index].text,
          this.format_interview_date(),
          this.get_user.avatar_uri,
          InterviewRoles.BOT
        );
        await this.update_chat_cursor();
        await this.playAudio(this.audio_text, false);
      } else {
        this.set_root_error(this.$t("errors.internal"));
      }
    },
    // Function to start speech recognition
    // If speech mode is active => stop speech recognition
    // If speech mode is inactive => start speech recognition
    async speak_config() {
      if (this.speech_mode) {
        this.speech_mode = false;
        this.recognition.stop();
      } else {
        this.speech_mode = true;
        this.transcript = "";
        this.recognition.start();
      }
    },
    // Function to play audio
    // If interview is completed => stop media recorder
    // If interview is not completed => speak config
    async playAudio(file: string, complete = false) {
      if (complete) {
        this.interview_completed = true;
        this.stop_media_recorder();
        // Complete interview
        this.set_root_notification(
          "Interview is Finished, Thank you for your time"
        );
        await this.$router.push("/candidate/dashboard");
      }
      this.ttsAudio = new Audio(file); // Create a new audio object
      // If interview is completed => stop media recorder
      const video: HTMLVideoElement = this.$refs.video as HTMLVideoElement;
      video.muted = true; // Mute video
      await video.play(); // Play video
      // Audio on ended function
      this.ttsAudio.onended = async () => {
        this.bot_speaking = false; // Set bot speaking to false
        video.pause(); // Pause video
        video.currentTime = 0; // Set video current time to 0
        this.transcript = ""; // Set transcript to empty
        // If interview is completed => stop media recorder
        await this.speak_config(); // If interview is not completed => speak config
      };
      await this.ttsAudio.play();
    },
    text_field_msg() {
      if (this.bot_speaking) {
        return this.$t("candidate.interview.speak").toString();
      } else return this.$t("candidate.interview.listening").toString();
    },
    /**
     * Function to complete interview & play thank you messages
     * @param {number}interview_id: Id required to complete interview
     */
    async complete_interview(interview_id: number) {
      const camera_recording_file_name = `${Date.now()}_user_${
        this.get_user.id
      }_interview_${interview_id}_recording.webm`;

      const cameraBlob = new Blob(this.recorded_blobs, {
        type: "video/webm"
      });
      const duration =
        +new Date(this.camera_recording_end_time) -
        +new Date(this.camera_recording_start_time);

      fixWebmDuration(cameraBlob, duration, async (fixedCameraBlob: Blob) => {
        const camera_recording = new File(
          [fixedCameraBlob],
          camera_recording_file_name,
          {
            type: "video/webm"
          }
        );
        this.media_uploading_title = "Upload Camera recording";

        await this.uploadFileWithChunks(
          camera_recording,
          camera_recording_file_name
        );

        // this.interview_history.push({
        //   created_at: this.format_interview_date(),
        //   picture: this.bot_image,
        //   content: this.interview_end_text,
        //   role: InterviewRoles.BOT,
        //   id: generate_random_key()
        // });
        // Play, thank you messages & Navigate back to the candidate interviews page
        await this.playAudio(this.interview_complete_msg, true);
      }); // Create a file from camera recording chunks
    },
    // Function to process interview history
    // Filter interview history
    // Remove interview history which contains job description, job skills, personal details & candidate profile
    // Set interview history
    // Update chat cursor
    async process_interview_history(
      history: Interview.InterviewHistoryResponse[]
    ) {
      this.interview_history = history
        .filter(
          (val) =>
            val.role !== InterviewRoles.SYSTEM &&
            !val.content
              ?.toLowerCase()
              .includes("job description and job skills is provided above") &&
            !val.content.toLowerCase().includes("personal_details") &&
            !val.content.toLowerCase().includes("candidate profile")
        )
        .map((val) => ({
          id: val.id,
          role: val.role,
          content: val.content,
          created_at: moment(val.created_at).format("ddd, h:mm A").toString(),
          picture:
            val.role === InterviewRoles.USER
              ? this.get_user.avatar_uri
              : this.bot_image
        }));
      await this.update_chat_cursor();
    },
    async update_chat_cursor() {
      await wait_until(1000);
      // await wait_until(1000);
      const box = this.$refs.chat_box as HTMLDivElement;
      if (box) box.scrollTop = box.scrollHeight;
    },
    stop_media_recorder() {
      // Stop media recorder
      if (this.media_recorder) this.media_recorder.stop();
      // Stop speech recognition
      if (this.recognition) this.recognition.stop();
      // Stop audio
      if (this.ttsAudio) this.ttsAudio.pause();
    },
    async uploadFileWithChunks(file: File, filename: string) {
      this.media_uploading = true; // Set media uploading to true
      let retry = 0;
      const chunkSize = 3 * 1024 * 1024; // 3MB chunk size
      let start = 0;
      // Loop until start is less than file size
      while (start < file.size) {
        let end = Math.min(start + chunkSize, file.size); // Get end index
        let chunk = file.slice(start, end); // Get chunk
        const formData = new FormData(); // Create a form data
        formData.append("file", chunk, filename); // Append chunk to form data
        // formData.append("start", start.toString()); // Append start index to form data
        formData.append("filename", filename); // Append file name to form data
        const response = await this.upload_file_chunk(formData); // Api call to upload file chunk
        if (response && retry < 15) {
          start = end; // If response exist then set start to end
          retry = 0;
          this.media_uploading_progress = Math.round((start / file.size) * 100); // Set media uploading progress
        } else if (retry >= 15) {
          this.set_root_error(`${this.$t("assessments.upload-file-error")}`);
          break;
        } else {
          retry += 1;
        }
      }
      this.media_uploading = false; // Set media uploading to false
      this.media_uploading_progress = 0; // Set media uploading progress to 0
      this.media_uploading_title = ""; // Set media uploading title to empty
    }
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    this.stop_media_recorder();
  }
});
