export interface InterviewChat {
  id: number;
  role: InterviewRoles;
  content: string;
  created_at: string;
  picture: string;
}

export enum InterviewRoles {
  BOT = "assistant",
  USER = "user",
  SYSTEM = "system"
}

export interface InterviewRecords {
  text: string;
  url: string;
}
